@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@300;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: Nunito;
  font-size: 16px;
  font-weight: 300;
}

h1,
h2,
h3,
h4,
h5,
h6,
p.bold {
  font-weight: 700;
}

h1 {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

h2 {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

h3 {
  font-size: 1.5rem;
  line-height: 2rem;
}

h4 {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

h5 {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

p {
  font-size: 1rem;
  line-height: 1.5rem;
}

p.small {
  font-size: 0.875rem;
  line-height: 1.25rem;
}
